import API from 'lib/api';
import { ROUTES } from 'lib/routes';
import { getTenant } from 'lib/session';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export function VerifyPI() {
  const api = new API()

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const tenant = getTenant()

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)

    const target = e.target as typeof e.target & {
      dob: { value: string }
      ssn: { value: string }
    }

    const response = await api.VerifyPI(
      tenant.cid,
      tenant.fid,
      target.dob.value,
      target.ssn.value,
    )

    if (response.success) {
      navigate(ROUTES.VERIFYOTP.path);
    } else {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1>First, let's confirm your personal information</h1>
        <p className="lead">
          Please enter your personal information below so we can verify your
          identity.
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="dob" className="form-label">
            Date of birth
          </label>
          <input className="form-control" type="date" name="dob" id="dob" />
        </div>
        <div className="mb-3">
          <label htmlFor="ssn" className="form-label">
            Last 4 of SSN
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="4312"
            name="ssn"
            id="ssn"
            maxLength={4}
            minLength={4}
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-lg w-100"
          disabled={isLoading}
        >
          Next
        </button>
      </form>
    </>
  )
}
