import { route } from "react-router-typesafe-routes/dom"; // Or /native

export const ROUTES = {
    START: route(
        // This is a normal path pattern, but without leading or trailing slashes.
        // By default, path params are inferred from the pattern.
        "start/:sess",
    ),
    VERIFYPI: route(
        'verifypi'
    ),
    VERIFYOTP: route(
        'verifyotp'
    ),
    DISBURSEMENT_SUMMARY: route(
        'disbursement/summary'
    ),
    DISBURSEMENT_PLAID: route(
        'disbursement/plaid'
    ),
};