import API from 'lib/api';
import { ROUTES } from 'lib/routes';
import { getTenant } from 'lib/session';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export function VerifyOTP() {
  const api = new API()

  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const tenant = getTenant()

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)

    const target = e.target as typeof e.target & {
      code: { value: string }
    }

    const response = await api.VerifyOTP(
      tenant.cid,
      tenant.fid,
      target.code.value,
    )

    if (response.success) {
      window.sessionStorage.setItem('token', response.token)
      navigate(ROUTES.DISBURSEMENT_SUMMARY.path);
    } else {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1>We've just sent you a verification code</h1>
        <p className="lead">
          We just emailed you a verification code. Please enter it below.
        </p>
      </div>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="code" className="form-label">
            Enter verification code
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="######"
            name="code"
            id="code"
            minLength={6}
            maxLength={6}
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-lg w-100"
          disabled={isLoading}
        >
          Next
        </button>
      </form>
    </>
  )
}
