import { Config } from 'lib/config';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { getToken } from './session';

type Plaid_CreateLinkTokenResponse = {
    data: {
        link_token: string;
    };
};

type Plaid_ExchangePublicTokenRequest = {
    public_token: string;
} & PlaidLinkOnSuccessMetadata;

type Plaid_ExchangePublicTokenResponse = {
    success: boolean;
};

type VerifyPIResponse = {
    success: boolean,
    token: string
}

type VerifyOTPResponse = {
    success: boolean,
    token: string
}

export enum PaymentStatus {
    NotSubmitted = 'not_submitted',
    Submitted = 'submitted'
}
export interface GetClientInfoResponse  {
    display_name: string
    disbursement_amount: number
    payment_status: PaymentStatus
}

export type GetFirmInfoResponse = {
    firm_name: string
    logo_url: string
}

export default class API {
    public async GetFirmInfo(firm_id: string) {
        const url = `${Config.API_BASE_URI}/firms/${firm_id}`;
        const response = await fetch(url, { method: 'GET' })
        const json = await response.json() as GetFirmInfoResponse;

        return json
    }

    public async VerifyPI(did: string, fid: string, dob: string, ssn: string) {
        const url = `${Config.API_BASE_URI}/disbursement/verify_pi`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fid,
                did,
                dob,
                ssn,
            })
        });

        const json = await response.json() as VerifyPIResponse;

        return json;
    }

    public async VerifyOTP(did: string, fid: string, otp: string) {
        const url = `${Config.API_BASE_URI}/disbursement/verify_otp`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fid,
                did,
                otp,
            })
        });

        const json = await response.json() as VerifyOTPResponse;
        
        return json;
    }

    public async GetClientInfo() {
        const url = `${Config.API_BASE_URI}/disbursement/info`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }
        });

        const json = await response.json() as GetClientInfoResponse;

        return json;
    }
    
    public async Plaid_CreateLinkToken () {
        const url = `${Config.API_BASE_URI}/plaid/create_link_token`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
        });

        const json = await response.json() as Plaid_CreateLinkTokenResponse;
        return json;
    }

    public async Plaid_ExchangePublicToken (request: Plaid_ExchangePublicTokenRequest) {
        const url = `${Config.API_BASE_URI}/plaid/exchange_public_token`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(request),
        });

        const json = await response.json() as Plaid_ExchangePublicTokenResponse;

        return json;
    }
}