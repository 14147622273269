import API from 'lib/api';
import { ROUTES } from 'lib/routes';
import React, { useEffect, useState } from 'react';
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';

const api = new API()

export function Plaid() {
  //   const navigate = useNavigate()
  const [linkToken, setLinkToken] = useState('')
  const generateToken = async () => {
    const res = await api.Plaid_CreateLinkToken()
    setLinkToken(res.data.link_token)
  }

  useEffect(() => {
    generateToken()
  }, [])

  return (
    <>
      <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1>Link your bank account with Plaid</h1>
        <p className="lead">
          We use Plaid to link your bank account. This lets us securely set up a
          direct deposit to your bank account.
        </p>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-10">Why Plaid?</h4>
          <p className="card-text">
            <i className="bi-cash"></i> Transfer money quickly and securely to
            your bank account.
          </p>
          <p className="card-text">
            <i className="bi-clock"></i> No physical check means your money gets
            deposited to your account faster.
          </p>
          <p className="card-text">
            <i className="bi-lock"></i> Sending your money via a deposit into
            your bank is more secure than a check.
          </p>

          <div>
            {linkToken ? (
              <Link linkToken={linkToken} />
            ) : (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface LinkProps {
  linkToken: string | null
}

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const navigate = useNavigate();

  const onSuccess = React.useCallback(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const response = await api.Plaid_ExchangePublicToken({
        public_token,
        ...metadata,
      })

      navigate(ROUTES.DISBURSEMENT_SUMMARY.path);
    },
    [navigate],
  )

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: props.linkToken!,
    onSuccess,
    linkCustomizationName: 'default',
  }
  const { open, ready } = usePlaidLink(config)

  return (
    //   <button onClick={() => open()} disabled={!ready}>
    //     Link account
    //   </button>
    <button
      className="btn btn-primary btn-lg w-100"
      onClick={() => open()}
      disabled={!ready}
    >
      Continue
    </button>
  )
}
