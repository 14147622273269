type Tenant = {
    cid: string;
    fid: string;
};

export const initTenant = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sess = urlParams.get('t') || '';

    if (sess) {
        const str = atob(sess)
        setTenantString(str)
    }

    return getTenant();
};

export const setTenantString = (str: string) => {
    window.sessionStorage.setItem('tenant', str);
};

export const setTenant = (tenant: Tenant) => {
    setTenantString(JSON.stringify(tenant));
};

export const getTenant = () => {
    const str = window.sessionStorage.getItem('tenant');
    return JSON.parse(str || '{}') as Tenant;
};

export const setToken = (token: string) => {
    window.sessionStorage.setItem('token', token);
};

export const getToken = () => {
    const token = window.sessionStorage.getItem('token');
    return token;
};
