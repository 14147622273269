import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from './error-page';
import './index.css';
import Root from './routes/Root';

import API, { GetFirmInfoResponse } from 'lib/api';
import { ROUTES } from 'lib/routes';
import { initTenant } from 'lib/session';
import { Plaid } from './routes/Disbursement/Plaid';
import { Start } from './routes/Disbursement/Start';
import { Summary } from './routes/Disbursement/Summary';
import { VerifyOTP } from './routes/Disbursement/VerifyOTP';
import { VerifyPI } from './routes/Disbursement/VerifyPI';


const api = new API();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTES.START.path,
        element: <Start />,
      },
      {
        path: ROUTES.VERIFYPI.path,
        element: <VerifyPI />,
      },
      {
        path: ROUTES.VERIFYOTP.path,
        element: <VerifyOTP />,
      },
      {
        path: ROUTES.DISBURSEMENT_SUMMARY.path,
        element: <Summary />,
      },
      {
        path: ROUTES.DISBURSEMENT_PLAID.path,
        element: <Plaid />,
      },
    ],
  },
])

function App() {
  const [firmInfo, setFirmInfo] = useState<GetFirmInfoResponse | null>(null)
  const [isLoading, setIsLoading] = useState(true);
  
  const getFirmInfo = async () => {
    const tenant = initTenant();
  
    if (!tenant) {
      throw Error('tenant not set')
    }
  
    const firm = await api.GetFirmInfo(tenant.fid);

    setFirmInfo(firm);
    setIsLoading(false);
  }

  useEffect(() => {
    console.log('useEffect')
    firmInfo || getFirmInfo()
  }, [firmInfo])
  
  return (
    <React.StrictMode>
      {
        isLoading ? (
          <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        ) : (
          <>
          <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
          <img
            src={firmInfo?.logo_url}
            width="300"
            height="68"
            className="d-inline-block align-top"
            alt=""
          />
        </div>
        <div className="container">
          <RouterProvider router={router} />
        </div>
        </>
        )
      }
  </React.StrictMode>
  );
}

export default App;
