import API, { GetFirmInfoResponse } from 'lib/api';
import { ROUTES } from 'lib/routes';
import { getTenant, setTenantString } from 'lib/session';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const api = new API()

export function Start() {
  const navigate = useNavigate()
  const { sess } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const [firmInfo, setFirmInfo] = useState<GetFirmInfoResponse | null>(null);

  const getFirmInfo = async (firmId: string) => {
    const info = await api.GetFirmInfo(firmId)
    setFirmInfo(info)
    setIsLoading(false)
  }

  // const sess = searchParams.get('t') || ''
  const str = atob(sess || '')
  setTenantString(str)

  useEffect(() => {
    console.log('useEffect')
    const tenant = getTenant();
    getFirmInfo(tenant.fid)
  }, [])

  return (
    <>
      {isLoading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1>Great news</h1>
            <p className="lead">
              We appreciate you choosing {firmInfo!.firm_name} to represent you.
              Click the link below to set up direct deposit and receive your
              money.
            </p>
          </div>
          <button
            type="button"
            className="btn btn-primary btn-lg w-100"
            onClick={() => navigate(ROUTES.VERIFYPI.path)}
          >
            Collect My Settlement
          </button>
        </>
      )}
    </>
  )
}
