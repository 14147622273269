import API, { PaymentStatus } from 'lib/api';
import { ROUTES } from 'lib/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const api = new API()
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export function Summary() {
  const navigate = useNavigate()
  const [clientName, setClientName] = useState('')
  const [disbursementAmountString, setDisbursmentAmountString] = useState('')
  const [paymentStatus, setPaymentStatus] = useState('')

  const getUserInfo = async () => {
    const info = await api.GetClientInfo()
    setClientName(info.display_name)
    setDisbursmentAmountString(
      currencyFormatter.format(info.disbursement_amount),
    )
    setPaymentStatus(info.payment_status)
  }
  useEffect(() => {
    getUserInfo()
  }, [])

  const click = () => {
    navigate(ROUTES.DISBURSEMENT_PLAID.path)
  }

  return (
    <>
      <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1>Welcome {clientName}</h1>
        <p className="lead">Please see your payout overview below</p>
      </div>

      <div className="card border-dark mb-3">
        <div className="card-body text-dark">
          <p className="text-muted card-title text-center f-mono">Your Payout</p>
          <p className="card-text text-center fw-bold">{disbursementAmountString}</p>

          {!paymentStatus || paymentStatus === PaymentStatus.NotSubmitted ? (
          <button className="btn btn-primary btn-lg w-100" onClick={click}>
            Request Direct Deposit
          </button>) : <div className="text-center font-weight-light"><span className="badge rounded-pill text-bg-primary">Pending</span></div>}
        </div>
      </div>
      
          {/* <h2>Recent Activity</h2>
          <div>Your direct deposit has been submitted.</div>
         */}
    </>
  )
}
